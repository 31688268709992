<template>
  <div class="records-paywall-page">
    <div class="limited_content">
      <div class="heading">
        <h1 class="title">{{ mainTitle }}</h1>
        <div class="sub-title heading-6">{{ subTitle }}</div>

        <mcr-loading-indicator :loading="plansLoading"></mcr-loading-indicator>
        <div class="button-container" v-if="canSubscribe && !plansLoading">
          <start-subscription-button
            :will-switch-to-annual="false"
            :plan="plan"
            :loading="stripeLoading"
            @start-subscription="startSubscription"
            >{{ buttonLabel }}</start-subscription-button
          >
          <div class="text-sm">{{ buttonSubLabel }}</div>
        </div>
      </div>
    </div>

    <div class="white-block">
      <div class="content">
        <div v-if="isTrialEligible">
          <h2>
            Unlimited access to millions of records.
            <span class="highlighted">Free for 7 days!<underline-image /></span>
          </h2>
        </div>
        <div v-else>
          <h2>
            Unlimited access to millions of records
            <span class="highlighted">at your fingertips!<underline-image /></span>
          </h2>
        </div>
        <div>
          <div class="heading-6 heading-5-mobile">
            Discover family tree books (zupu), immigration files, tombstones, and more!
          </div>

          <div class="button-container" v-if="canSubscribe && !plansLoading">
            <start-subscription-button
              :will-switch-to-annual="false"
              :plan="plan"
              :loading="stripeLoading"
              @start-subscription="startSubscription"
              >{{ buttonLabel }}</start-subscription-button
            >
            <div class="text-sm">{{ buttonSubLabel }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="logo-wall-wrapper">
      <hr />
      <div class="heading-4 heading-4-mobile">Trusted by heritage keepers worldwide.</div>
      <logo-wall></logo-wall>
      <hr />
    </div>

    <end-actions v-if="canSubscribe && !plansLoading">
      <div class="heading-2">Secure your early membership now!</div>
      <div>Write the future of Chinese genealogy together, starting with your story.</div>
      <start-subscription-button
        class="button"
        :will-switch-to-annual="false"
        :plan="plan"
        :loading="stripeLoading"
        @start-subscription="startSubscription"
        >{{ buttonLabel }}</start-subscription-button
      >
    </end-actions>
  </div>
</template>

<script>
import EndActions from '@common/elements/buttons/endActions';
import UnderlineImage from '@common/elements/icons/UnderlineImage';

import LogoWall from '@/components/common/images/LogoWall';

import StartSubscriptionButton from '@/components/modules/subscriptionPlans/StartSubscriptionButton';

export default {
  props: {
    plansLoading: Boolean,
    stripeLoading: Boolean,
    canSubscribe: Boolean,
    isTrialEligible: Boolean,
    standardTrialDays: Number,
    plan: Object,
  },
  computed: {
    mainTitle() {
      return 'Chinese genealogy made easy';
    },
    subTitle() {
      return `Your one-stop shop to research and preserve your family history.`;
    },
    buttonLabel() {
      if (this.isTrialEligible) {
        return `Start Free ${this.standardTrialDays} Day Trial`;
      }
      return 'Become a Member to View Records';
    },
    buttonSubLabel() {
      const cost = this.plan ? this.plan.cost : '';
      const costText = `$${cost}/year`;
      const trialDays = this.plan ? this.plan.trial_days : 0;
      return this.isTrialEligible ? `${costText} after ${trialDays} day trial` : costText;
    },
  },
  methods: {
    startSubscription() {
      this.$emit('start-subscription', this.plan.id);
    },
  },
  components: {StartSubscriptionButton, LogoWall, EndActions, UnderlineImage},
  name: 'RecordsPaywallPage',
};
</script>

<style lang="scss" scoped>
.records-paywall-page {
  .limited_content {
    padding-top: 110px;
    padding-bottom: 0;
  }
  .heading {
    max-width: 680px;
    margin: 0 auto;
    text-align: center;

    .title {
      margin: 0;
    }

    .sub-title {
      margin: 20px auto 48px;
      color: $neutral-600;
      font-weight: 400;
    }
  }

  .button-container {
    max-width: 315px;
    margin: 0 auto;

    .mcr-button {
      margin-bottom: 8px;
    }
    .text-sm {
      text-align: center;
    }
  }

  .white-block {
    background: $background-light;
    padding: 96px;
    margin-top: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      display: flex;
      justify-content: space-between;
      max-width: 956px;
      column-gap: 72px;

      .heading-6 {
        margin: 0 0 32px 0;
        color: $neutral-600;
        font-weight: 400;
      }
      .highlighted {
        white-space: nowrap;
        position: relative;
        .underline-image {
          position: absolute;
          left: -16px;
          bottom: -24px;
        }
      }
      .button-container {
        margin: 0;
        max-width: 100%;
      }
    }
  }

  .logo-wall-wrapper {
    margin: 120px auto 140px;
    padding: 0 16px;
    max-width: $content-max-width;

    .heading-4 {
      text-align: center;
      margin: 48px 0 48px;
    }

    div {
      margin-bottom: 48px;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .limited_content {
      padding-top: 56px;
    }
    .sub-title {
      margin: 16px auto 32px;
    }
    .white-block {
      padding: 32px 24px;
      margin-top: 128px;

      .content {
        flex-direction: column;
        row-gap: 56px;
      }
    }
  }
}
</style>
